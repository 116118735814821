
.useridlandscapecrbn {
    position: absolute;
    bottom: 4.5%;
    right: 8%;
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
}

.useridwindowscrbn {
    position: absolute;
    top: 2.5%;
    left: 9%;
    /* right: 6%; */
    color: #f7f812;
    font-size: 44px;
    font-family: 'SAGONABOOKBOLD';
    z-index: 1;
}

.useridportraitecrbn {
    position: absolute;
    top: 8.5%;
    left: 10%;
    /* transform: scale(1.7,0.7); */
    /* right: 72%; */
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
}

.balancewindowscrbn {
    position: absolute;
    top: 1.5%;
    left: 29%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
}

.balanceportraitecrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    /* top: 0%;
    left: 28%; */
    /* transform: scale(1.5,0.6); */
    top: 4%;
    left: 20%;
    color: #fffe00;
    font-size: 45px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}

.balancelndscapecrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 3%;
    left: 15%;
    color: #fffe00;
    font-size: 58px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}

.betsportraitecrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    left: 50%;
    /* transform: scale(2.5,0.55); */
    color: #fffe00;
    font-size: 45px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}

.betslandspecrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 13%;
    left: 8%;
    color: #fffe00;
    font-size: 58px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}
.betswindowscrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 1.5%;
    left: 50%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    z-index: 1;
}


.winswindowscrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 1%;
    /* right: 23.7%; */
    /* background: red; */
    left: 76%;
    color: #fffe00;
    font-size: 44px;
    font-family: 'Poppins';
    font-weight: bold;
    z-index: 1;
    /* width: 133px; */
    /* height: 51px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.winsportraitecrbn {
    position: absolute;
    top: 4%;
    right: 20%;
    color: #fffe00;
    /* transform: scale(2.5,0.55); */
    font-size: 45px;
    width: 10%;
    /* height: 13%; */
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}

.winslandspecrbn {
    position: absolute;
    /* transform: translate(179px,709px); */
    top: 4%;
    /* left: 8%; */
    right: 12%;
    color: #fffe00;
    font-size: 58px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
}

.foldtextportraitecrbn {
    position: absolute;
    /* transform: translate(357px,577px); */
    /* transform: scale(1.4,0.5); */
    color: white;
    font-size: 40px;
    top: 68.5%;
    left: 84.5%;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    z-index: 10;
    pointer-events: none;
}

.topcards {
    /* background: red; */
    width: 51%;
    position: absolute;
    top: 20%;
    left: 24%;
    height: 10%;
}
.betpannelcarebean {
    /* background: yellow; */
    position: absolute;
    top: 938px;
    left: 972px;
    width: 16%;
    z-index: 10;
    /* width: 16%; */
    /* background: red; */
    /* height: 175%; */
    /* display: flex; */
    /* z-index: 1; */
}
.betpannelcarebean_lndspe {
    /* background: yellow; */
    position: absolute;
    top: 938px;
    left: 972px;
    z-index: 10;
    /* width: 16%; */
    /* background: red; */
    /* height: 175%; */
    /* display: flex; */
    /* z-index: 1; */
}
.antebet_carebeanportrite {
    position: absolute;
    top: 494px;
    left: -640px;
    width: 254px;
    /* background: rgb(0 137 18); */
    /* height: 96%; */
    border-radius: 55%;
    /* pointer-events: none; */
}
.antebet_carebean {
    position: absolute;
    top: 1px;
    left: 18px;
    width: 35%;
    /* background: rgb(0 137 18); */
    /* height: 96%; */
    border-radius: 55%;
    /* pointer-events: none; */
}
.bet_carebeanportraite {
    position: absolute;
    top: 497px;
    left: -331px;
    width: 254PX;
    /* background: rgb(0 137 18); */
    /* height: 96%; */
    border-radius: 55%;
}
.bet_carebean {
    position: absolute;
    top: 0px;
    left: 255px;
    width: 35%;
    /* background: rgb(0 137 18); */
    /* height: 96%; */
    border-radius: 55%;
}
.anteglowportraite {
    position: fixed;
    left: 325px;
    bottom: -1420px;
    width: 266px;
    height: 276px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: relative;
}
.anteglow {
    top: 933px;
    left: 983px;
    width: 146px;
    height: 143px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: absolute;
}
.betglow {
    top: -5px;
    left: 250px;
    width: 141px;
    height: 143px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: absolute;
}

.betactivePortrait_crbn {
    top: 485px;
    left: -337px;
    width: 260px;
    height: 275px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: absolute;
}

.betactivelndspe_crbn {
    top: -586px;
    left: 554px;
    width: 194px;
    height: 190px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: absolute;
}

.carebean_anteportraite {
    position: absolute;
    top: 1522px;
    left: 386px;
    font-size: 62px;
    font-family: 'Poppins';
    color: #ffffff;
    z-index: 6;
}
.carebean_antelndspe {
    position: absolute;
    /* top: 7px; */
    bottom: 481px;
    left: 1610px;
    font-size: 44px;
    font-family: 'Poppins';
    color: #ffffff;
    /* z-index: 6; */
}
.carebean_ante {
    position: absolute;
    top: 996px;
    left: 1045px;
    font-size: 25px;
    font-family: 'Poppins';
    color: #ffffff;
    /* background: red; */
    z-index: 6;
    pointer-events: none;
}
.carebean_bet {
    position: absolute;
    top: 49px;
    left: 301px;
    font-size: 25px;
    font-family: 'Poppins';
    color: #ffffff;
    /* z-index: 6; */
}
.carebean_betportraite {
    position: absolute;
    top: 574px;
    left: -252px;
    font-size: 62px;
    font-family: 'Poppins';
    color: #ffffff;
}

.betwindows_rebetcrbn{
    position: absolute;
    top: 76.5%;
    left: 67.4%;
    color: #fff;
    font-size: 35px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;

}
.betwindowscrbn {
    position: absolute;
    top: 77.3%;
    left: 68.4%;
    color: #fff;
    font-size: 35px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    z-index: 2;
    /* background: red; */
    pointer-events: none;
    width: 138px;
    height: 118px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
}
.betportraitcrbn{
    position: absolute;
    top: 73.5%;
    left: 74%;
    color: #fffcfc;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 11%;
    height: 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.betLandscapecrbn {
    position: absolute;
    top: 63.5%;
    left: 82.5%;
    color: #fffcfc;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 6%;
    height: 8%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}



.winPopupForMptIntlcrbn{
    visibility: hidden;
    opacity: 0;
}
.winPopupLand_crbn {
    position: absolute;
    top: 822px;
    left: 0%;
    width: 8%;
    height: 69px;
    border: solid 5px yellow;
    border-radius: 50px;
    animation: blink normal 1s infinite ease-in-out;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.winPopupForMptSw_crbn {
    position: absolute;
    top: 1930px;
    left: 1%;
    width: 17%;
    height: 69px;
    border: solid 5px yellow;
    border-radius: 50px;
    animation: blink normal 1s infinite ease-in-out;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.winpopup_caribbean {
    position: absolute;
    /* margin-top: 36%; */
    top: 578px;
    left: 70%;
    width: 219px;
    height: 69px;
    border: solid 8px yellow;
    border-radius: 50px;
    animation: borderBlink 0.4s infinite ease-in-out;
    /* border: 5px yellow; */
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
/* Keyframes for blinking border */
@keyframes borderBlink {
    0% {
        border-color: yellow;
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: yellow;
    }
}
.popupbetshighcrbn {
    position: relative;
    top: 208px;
    left: 781px;
    width: 764px;
    height: 257px;
    background: hsl(282.94deg 12.16% 78.65%);
    z-index: 79;
    box-shadow: 8px 14px #0c0303;
    border-radius: 30px;
}

.popupbetshighMobilecrbn{
    visibility: hidden;
    opacity: 0;
    

}
.popupbetshighwindows {
    position: relative;
    top: 424px;
    left: 732px;
    width: 878px;
    height: 319px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 25;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.poptext_betshighcrbn {
    position: absolute;
    /* top: 12px; */
    text-align: center;
    padding: 88px 21px;
    font-size: 41px;
    color: #230303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
}
.poptextBetshighwindowscrbn {
    position: relative;
    top: -3px;
    padding: 10px 77px;
    font-size: 50px;
    color: #230303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
    text-align: center;
}
.popupbetshighwindows {
    position: relative;
    top: 158px;
    left: 728px;
    width: 900px;
    height: 296px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 25;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.popupbetshighPortrait_crbn {
    position: relative;
    top: 538px;
    left: 163px;
    width: 900px;
    height: 400px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 25;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.poptextBetshighPortraitcrbn{
    position: absolute;
    /* top: 12px; */
    padding: 10px 77px;
    font-size: 50px;
    color: #230303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
    text-align: center;
}




.attention_textcrbn {
    position: absolute;
    top: 15px;
    margin-left: 209px;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    padding: -6px 167px;
    font-size: 65px;
    color: #1d0303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
}

.attentionTextPortraitcrbn{
    position: relative;
    font-size: 80px;
    color: #1d0303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
    margin-bottom: 6%;
}

.chipglow_caribbean {
    position: absolute;
    top: 559px;
    /* margin-bottom: 74%; */
    /* margin-top: 831.5px; */
    left: 1692px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    z-index: 1;
    border: 10px solid yellow;
    animation: blink normal 1s infinite ease-in-out;
}

.winamt_crbn {
    position: absolute;
    /* margin-top: 1%; */
    top: -2%;
    left: 3%;
    color: #ffffff;
    font-size: 50px;
    height: 58px;
    width: 198px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: red; */
    text-align: center;
}
.winamtlnd_crbn{
    color: #ffffff;
    font-size: 40px;
    text-align: center;
}




.foldtextportraitcaribben{
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11%;
    height: 6%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

}
.foldtextLandscapecrbn{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 84.5%;
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 8%;
    flex-wrap: wrap;
    overflow-wrap: anywhere;

}



.rebettextportraitcrbn{    
    position: absolute;
    top: 66.5%;
    left: 83%;
    color: #fffcfc;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    pointer-events: none;
    z-index: 10;
    width: 11%;
    height: 6%;
    text-align: center;
    overflow-wrap: anywhere;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}
.rebettextwindows_crbn {
    position: absolute;
    top: 64.5%;
    left: 75.8%;
    color: white;
    font-size: 31px;
    font-family: 'SAGONABOOKBOLD';
    font-weight: bold;
    z-index: 23;
    width: 105px;
    text-align: center;
    display: flex;
    overflow-wrap: anywhere;
    /* background: red; */
    width: 117px;
    height: 128px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}


.bet-text-addtoBet_carr {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 49px;
    left: 294px;
    font-size: 31px;
    font-family: monospace;
    color:white;
    /* color: #370707; */
    z-index: 7;
    width: 56px;
    font-weight: bold;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    pointer-events: none;
}
.bet-text-addtoBet_carr-portrait {
    position: absolute;
    font-size: 40px;
    left: 43%;
    bottom: -26.5%;
    /* top: 129.6%; */
    z-index: -2;
    display: flex;
    flex-direction: column;
    width: 10%;
    /* background: red; */
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    font-family: 'monospace';
    font-weight: bold;
}
.bet-text-addtoBet_carr-lndsp {
    position: absolute;
    font-size: 40px;
    width: 6%;
    height: 5%;
    /* background: red; */
    font-family: 'monospace';
    font-weight: bold;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2%;
    z-index: -2;
}

.bet-text-addtoBet20crbn{
    color:#ffffffe1;
}

.bet-text-addtoBet10crbn{
    color:#ffffffe1;
}


.crbn_nodrop {
    cursor: no-drop;
}




.carebeanantechip_10 {
    position: absolute;
    top: 960.4px;
    /* top: 613.4px; */
    left: 1009px;
    z-index: 19;
    width: 4%;
    pointer-events: none;
}

.carebeanantechip_20 {
    position: absolute;
    top: 960px;
    left: 1010px;
    z-index: 19;
    width: 4%;
    pointer-events: none;
}

.carebeanantechip_50 {
    position: absolute;
    top: 961px;
    left: 1009px;
    z-index: 19;
    width: 4%;
    pointer-events: none;
}

.carebeanantechip_100 {
    position: absolute;
    top: 960px;
    left: 1012px;
    z-index: 19;
    width: 4%;
    pointer-events: none;
}
.antebet_caribean500{
  
        position: absolute;
        /* margin-top: 25.3%; */
        /* margin-left: 48.5%; */
        top: 983px;
        left: 1025px;
        font-size: 22px;
        font-family: 'Poppins';
        color: #ffffff;
        /* color: #370707; */
        z-index: 19;
        font-weight: bold;
        width: 68px;
        height: 50px;
        /* background: #2b0404; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        pointer-events: none;
    

}
.carebeanantechip_500 {
    position: absolute;
    top: 960px;
    left: 1011px;
    z-index: 19;
    width: 4%;
    pointer-events: none;
}

.chipsstoredcontainerLndsp_crbn {
    position: relative;
    left: 66.8%;
    /* right: 0; */
    margin: auto;
    top: 438px;
    bottom: 0;
    z-index: 2;
}
.chip-center-position_crbn {
    position: relative;
    width: 18%;
    /* top: 0; */
    top: 1265px;
    left: 27.7%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 35; */
}
.chip-center-position_crbn-lndsp {
    position: relative;
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 287px;
}


.undobetbutton {
    position: absolute;
    top: 500px;
    left: 5px;
    height: 100px;
    z-index: 9;
    width: 140px;
    background: red;
}

.chip20centerpositionlndspe_crbn{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}

.chip20centerpositionPortrait_crbn {
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
    position: absolute;
    top: 183px;
}
/* 
.chip50centerpositionPortrait {
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
}

.chip100centerpositionPortrait{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
} */


/* 
.chip500centerpositionPortrait{
    position: absolute;
    z-index: 9;
    width: 100%;
    pointer-events: none;
    

} */



.container_carebean {
    position: absolute;
    top: -455px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 57%;
}
#container_carebean h1::before {
    content: "DEALER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
 
}
#container_carebean h2::before {
    content: "PLAYER";
    color: #a1a1a1;
    position: absolute;
    -webkit-text-stroke: 3px #bcd7bc;
}
.infotext_caribben {
    position: relative;
    top: 818px;
    right: 1px;
    width: 441px;
    height: 266px;
    border: 5px solid slategrey;
    /* border-image: linear-gradient(to right, #a29585, #976565, #296790, #403838); */
    border-image-slice: 1;
    border-radius: 16px;
}
.Dealercrbn {
    font-family: "SAGONABOOKBOLD";
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    left: 564px;
}
.Playercrbn {
    font-family: "SAGONABOOKBOLD";
    color: #a1a1a1;
    font-size: 55px;
    position: absolute;
    right: 585px;
    bottom: 65px;
   
}
.pyrdlr_crbnprt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 881px;
    position: absolute;
    top: 37%;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    padding: 50px 0;
}
.popupbetshighwindowscrbn {
    position: relative;
    top: 170px;
    left: 732px;
    width: 878px;
    height: 319px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pyrdlr_crbnlnsp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 52%;
    height: 91%;
    position: absolute;
    top: 3%;
    left: 22%;
    font-size: 38px;
    font-family: "Poppins";
    /* margin: auto; */
}

.antebetChipPortrait20_crbn {
    position: absolute;
    z-index: 9;
    font-size: 50px;
    color: #ffffff;
    display: flex;
    width: 12%;
    top: 253px;
    justify-content: center;
}



.addpointercrbn {
    cursor: pointer;
}

/* 
.windowsinfo {
    position: absolute;
    top: 900px;
    font-size: 25px;
    font-family: "SAGONABOOKBOLD";
    z-index: 5;
    color: #ffffff;
    width: 755px;
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    left: 0;
    right: 0;
    margin: auto;
} */
.portraitinfo_crbn {
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 316px;
    position: fixed;
    font-size: 40px;
    /* left: 364px; */
    font-family: "SAGONABOOKBOLD";
    z-index: 5;
    color: #ffffff;
    align-items: center;
    justify-content: center;
}
.lndspinfo_crbn {
    position: absolute;
    width: 24%;
    height: 400px;
    top: 423px;
    left: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 4px solid rgba(0,255,173,.6901960784313725);
    border-radius: 10%;
}
.infotext_lndspecrbn {
    font-family: "SAGONABOOKBOLD";
    font-size: 44px;
    color: white;
}

.crbn_playerrank {
    position: absolute;
    top: 759px;
    /* margin-top: 21%; */
    left: 61.1%;
    color: black;
    font-size: 33px;
    font-family: "SAGONABOOKBOLD";
    z-index: 5;
    background: #8d8c6ff5;
    width: 256px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
       font-weight: bold;
    border: 3px solid #a2c670;
    border-radius: 16px;
    box-shadow: 0px 8px #a1bc82;
}
.pokerrankingPortait_crbn {
    position: absolute;
    top: 1328px;
    /* margin-top: 20.8%; */
    /* left: 36%; */
    left: 31%;
    color: black;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    z-index: 15;
    background: #8d8c6ff5;
    font-weight: bold;
    border: 3px solid #a2c670;
    border-radius: 16px;
    box-shadow: 0px 13px #a1bc82;
    width: 409px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.mobileAntiMaskCover_crbnlndspe{
  
        display: flex;
        justify-content: center;
        /* background-color: transparent; */
        position: absolute;
        top: 239px;
        left: 443px;
        width: 59%;
        height: 722px;
        background: red;
        z-index: 10;
        /* background-color: #8825ab00; */
        /* border-radius: 50%; */
    
}
.pokerrankingPlayerLndsp_crbn {
    position: absolute;
    top: 865px;
    left: 40.2%;
    color: black;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    z-index: 15;
    background: #8d8c6ff5;
    font-weight: bold;
    border: 3px solid #a2c670;
    border-radius: 16px;
    box-shadow: 0px 13px #a1bc82;
    width: 350px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.bet-text-portrait_crbn {
    position: absolute;
    font-size: 50px;
    left: -265PX;
    color: white;
    /* bottom: 117PX; */
    top: 2307PX;
    z-index: -2;
    display: flex;
    flex-direction: column;
    width: 63%;
    /* background: red; */
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    font-family: 'monospace';
    font-weight: bold;
}
.bet-text-lndsp_crbn {
    position: absolute;
    font-size: 37px;
    width: 80%;
    height: 27%;
    color: #ffffff;
    /* background: red; */
    font-family: 'Poppins';
    font-weight: bold;
    top: -670px;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 1239px;
    /* right: -63%; */
    z-index: -2;
}
.bet-text-crbnt20{
    color:#ffffffe1;
}

.bet-text-crbn10{
    color:#ffffffe1;
}
.poptextBetshighwindowscaribbean {
    position: relative;
    top: 42px;
    padding: 10px 77px;
    font-size: 50px;
    color: #230303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
    text-align: center;
}
.attention_textcaribbean {
    position: absolute;
    top: -56px;
    margin-left: 209px;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    padding: -6px 167px;
    font-size: 65px;
    color: #1d0303;
    font-weight: bold;
    font-family: "SAGONABOOKBOLD";
}
.windowscareabean_dealer {
    position: absolute;
    top: 269px;
    /* margin-top: 22%; */
    left: 62.2%;
    color: black;
    font-size: 30px;
    font-family: "SAGONABOOKBOLD";
    z-index: 5;
    background: #8d8c6ff5;
    width: 256px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 3px solid #a2c670;
        border-radius: 16px;
        box-shadow: 0px 8px #a1bc82;


}
.undotextwindows {
    position: absolute;
    top: 89.2%;
    left: 61.6%;
    font-size: 29px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    color: #ffffff;
    z-index: 1;
    text-align: center;
    pointer-events: none;
    /* background: red; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}
.undotext_lndspe {
    position: absolute;
    top: 81.2%;
    left: 77.6%;
    font-size: 38px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    color: #ffffff;
    z-index: 1;
    text-align: center;
    pointer-events: none;
    /* background: red; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}

.antebetChiplndspe20_crbn {
    position: absolute;
    z-index: 9;
    font-size: 41px;
    color: #ffffff;
    display: flex;
    width: 12%;
  
    top: -32px;
    justify-content: center;
}
.undotextportraite {
    position: absolute;
    top: 79.8%;
    left: 60.6%;
    font-size: 38px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    color: #ffffff;
    z-index: 2;
    text-align: center;
    pointer-events: none;
    /* background: red; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}


.pokerrankingdelerPortrait_crbn {
    position: absolute;
    left: 34.5%;
    top: 777px;
    color: black;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    z-index: 15;
    background: #8d8c6ff5;
    font-weight: bold;
    border: 3px solid #a2c670;
    border-radius: 16px;
    box-shadow: 0px 8px #a1bc82;
    width: 350px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.crbndealer_rank {
    position: absolute;
    left: 41%;
    top: 398px;
    color: black;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    z-index: 15;
    background: #8d8c6ff5;
    font-weight: bold;
    border: 3px solid #a2c670;
    border-radius: 16px;
    box-shadow: 0px 13px #a1bc82;
    width: 350px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.antebet_caribean10 {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 987px;
    left: 1038px;
    font-size: 31px;
    font-family: monospace;
   color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    width: 38px;
    font-weight: bold;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    pointer-events: none;
}
.antebet_caribean20 {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 992px;
    left: 1040px;
    font-size: 25px;
    font-family: monospace;
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 38px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
}
.antebet_caribean50 {
    position: absolute;
    top: 987px;
    left: 1029px;
    font-size: 31px;
    font-family: monospace;
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 60px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    pointer-events: none;
}
.antebet_caribean100 {
    position: absolute;
    /* margin-top: 25.3%; */
    /* margin-left: 48.5%; */
    top: 992px;
    left: 1041px;
    font-size: 25px;
    font-family: monospace;
    color: #ffffff;
    /* color: #370707; */
    z-index: 19;
    font-weight: bold;
    width: 38px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
}





.valtext_windows_chip10crbn {
    font-size: 31px;
    font-family: monospace;
    position: absolute;
    bottom: 6.1%;
    font-weight: bold;
    width: 53px;
    font-weight: bold;
    /* background: #34bf1b; */
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 202px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.incrementval_windowscrbn {
    color: #ffffff;
    font-size: 36px;
    font-family: monospace;
    position: absolute;
    font-weight: bold;
    bottom: 6%;
    font-weight: bold;
    width: 53px;
    height: 38px;
    /* background: #b31212; */
    /* top: 5%; */
    /* left: 5%; */
    z-index: 4;
    right: 201px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-items: flex-start; */
}

.valtextPortrait_crbn {
    color: #ffffff;
    font-size: 58px;
    font-family: 'SAGONABOOKBOLD';
    position: fixed;
    bottom: 3%;
    z-index: 4;
    /* background: red; */
    right: 46.5%;
    width: 8%;
    height: 4%;
    text-align: center;
    /* display: flex; */
    font-weight: bold;
}
.valtextLandscapecrbn{
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    position: absolute;
    bottom: 48%;
    z-index: 4;
    right: 2.5%;
    width: 5%;
    display: flex;
    justify-content: center;

}

.valtextPortrait1_crbn{
    color: white;
    
}


/* 
.grid table {
    position: absolute;
   
    width: 15%;
    height: 333px;
    right: 3.5%;
    margin-top: 5%;

    font-family: arial, sans-serif;
    border-collapse: collapse;
    border: 4px #edeb8b solid;
   
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

.popup_cnfrmbuydealrcardcrbn {
    position: absolute;
    top: 131%;
    left: 32%;
    width: 869px;
    height: 250px;
    background-color: #ffffff;
    border-radius: 25px;
    z-index: 3000;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    /* box-shadow: 6px 7px 100px peachpuff; */
}
.popup_Portraitcrbn {
    position: absolute;
    top: 800px;
    left: 13.5%;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 22;
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.nobutton_popupcrbn {
    position: absolute;
    top: 72%;
    left: 49%;
    width: 137px;
    height: 50px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 24px;
    font-family: 'SAGONABOOKBOLD';
    /* border: 1px solid; */
    background-color: #931111;
}
.nobutton_popup_portraitcrbn {
    position: absolute;
    top: 65%;
    left: 55%;
    width: 150px;
    height: 70px;
    border-radius: 16px;
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    /* border: 1px solid; */
    background-color: #931111;
}

.yesbutton_popupcrbn {
    position: absolute;
    top: 72%;
    left: 32%;
    color: #ffffff;
    font-size: 24px;
    font-family: 'SAGONABOOKBOLD';
    width: 137px;
    height: 50px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}

.yesbutton_popup_portraitcrbn{
    position: absolute;
    top: 65%;
    left: 30%;
    color: #ffffff;
    font-size: 40px;
    font-family: 'SAGONABOOKBOLD';
    width: 150px;
    height: 70px;
    border: 2px solid;
    background-color: #536505;
    border-radius: 16px;
}
.popup_cnfrmfoldcrbn {
    position: absolute;
    top: 609px;
    bottom: 0;
    margin: auto;
    left: 107px;
    right: 0;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 22;
    visibility: visible;
}
.bet_pannelcaribbean_CRBN {
    position: absolute;
    top: 709px;
    left: 38%;
    width: 542px;
    height: 382px;
    /* background: red; */
    z-index: 27;
}
.PlayerCardpannelcovercrbn{
    display: flex;
    justify-content: center;
    background-color: transparent;
    position: absolute;
    top: 64%;
    left: 25%;
    width: 50%;
    height: 12%;
    z-index: 15;
}
.maskcoverlndspe_crbn {
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 275px;
    left: 101px;
    width: 76%;
    height: 639px;
    /* background: red; */
    z-index: 10;
    /* background-color: #8825ab00; */
    /* border-radius: 50%; */
}
.mobileAntiMaskCover_crbn {
    display: flex;
    justify-content: center;
    /* background-color: transparent; */
    position: absolute;
    top: 1141px;
    left: 50px;
    width: 76%;
    height: 547px;
    /* background: red; */
    z-index: 10;
    /* background-color: #8825ab00; */
    /* border-radius: 50%; */
}
.crbn_MainDiv_crbn {
    position: absolute;
    position: fixed !important;
    width: 102%;
    height: 101%;
    left: -1%;
    top: -10px;
    /* background-color: red; */
    /* top: 102%; */
}



.player-dealer-card-containercrbn {
    /* border: 2px solid red; */
    /* background-color: #f8124ecf; */
    height: 689px;
    width: 100%;
    position: absolute;
    /* left: 46%; */
    top: 18%;
    z-index: 1;
    display: flex;
    /* display: none; */
    justify-content: center;
    /* border-radius: 100%; */
}
.stage-top-mobile-portraitcrbn {
    top: 14%;
    left: 0.5%;
}
.stage-top-mobile-landscape{
    top: 20%;
    left: 2.5%;
}

.stage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 17px solid green;
    position: absolute;
    top: 12%;
    z-index: 111;
    /* left: 50%; */
    width: 100%;
}

.alertTxtcrbn {
    color: black;
    font-size: 33px;
    text-align: center;
    /* justify-content: center; */
    position: absolute;
    padding: 20px;
}
.alertTxtPortraitcrbn{
    color: black;
    font-size: 40px;
    text-align: center;
    justify-content: center;
    position: absolute;
    padding: 20px;
}


.foldtextwindowscrbn {
    position: absolute;
    top: 67.5%;
    left: 75.6%;
    font-size: 35px;
    font-family: "SAGONABOOKBOLD";
    font-weight: 700;
    color: #ffffff;
    z-index: 1;
    /* background: red; */
    width: 128px;
    height: 40px;
    text-align: center;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}
/* span#antebetval_10 {
    position: absolute;
    top: 595px;
    left: 1135px;
    font-size: 30px;
    font-family: monospace;
    z-index: 11;
    color: rgb(14, 14, 236);
    font-weight: bold;
} */


.playerIdDetailswindowscrbn {
    position: absolute;
    /* width: 79%; */
    color: #fff;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 25px;
    left: 7%;
    top: 7%;
    font-family: 'monospace';
    z-index: 5;
}

.playerIdDetailsPortraitcrbn{
    position: absolute;
    width: 32%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: end;
    /* font-size: 40px; */
    font-size: 35px;
    left: 65%;
    top: 8.7%;
    font-family: 'monospace';
}
.playerIdDetailsLandscapecrbn {
    position: absolute;
    width: 20%;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    bottom: 3%;
    left: 3%;
    font-family: 'monospace';
}

.minmaxbets_windowscrbn {
    position: absolute;
    top: 201px;
    left: 153px;
    font-size: 34px;
    font-family: "SAGONABOOKBOLD";
    display: flex;
    flex-direction: column;
    color: white;
}

.minmaxbets_portraitcrbn {
    position: absolute;
    top: 270px;
    left: 2%;
    font-size: 35px;
    font-family: "SAGONABOOKBOLD";
    display: flex;
    flex-direction: column;
    color: #fff;
   
}
.minmaxbets_lndspcrbn{
    position: absolute;
    top: 250px;
    left: 2%;
    font-size: 40px;
    font-family: "SAGONABOOKBOLD";
    display: flex;
    flex-direction: column;
    color: #fff;
}
.optionsmenucrbn {
    position: fixed;
    top: 6%;
    left: -21.5%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_portraite_crbn {
    position: fixed;
    top: 6%;
    left: -41.5%;
    /* height: 611px; */
    height: 400px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

}
.optionsmenu_lndspe_crbn {
    position: fixed;
    top: 5%;
    left: -22%;
    height: 611px;
    width: 309px;
    background-color: #daebf6;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
/* .exithistory {
    animation: anim 1s ease-in-out forwards;
}

@keyframes anim {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.5);
    }
} */



.optionheadingcrbn {
    font-size: 37px;
    padding: 32px;
    font-family: 'Poppins';
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgb(44, 22, 1);
}
.limitstext_crbn {
    position: absolute;
    /* top: 26%; */
    top: 15%;
    left: -87%;
    height: 80px;
    width: 257px;
    /* background-color: #a9761d; */
    background: linear-gradient(120deg, #f3a11b80, #9b8484);
    border-radius: 36px;
    color: black;
    font-size: 35px;
    font-family: 'Poppins';
}

.helptext_crbn {
    position: absolute;
    /* top: 48%; */
    top: 40%;
    left: -87%;
    height: 80px;
    width: 257px;
    animation: gradient;
    background: linear-gradient(46deg, #170101, #8b424200);
    /* background-color: #414041; */
    border-radius: 36px;
    color:black;
    font-size: 35px;
    font-family: 'Poppins';
}
.historytext_crbn {
    position: absolute;
    /* top: 70%; */
    top: 65%;
    left: -87%;
    height: 80px;
    width: 257px;
    background: linear-gradient(45deg, blue, #dcc0c0, white);
    border-radius: 36px;
    color:black;
    font-size: 35px;
    font-family: 'Poppins';
}

.arraowtextcrbn {
    position: absolute;
    top: 88%;
    left: 16.5%;
    height: 57px;
    width: 201px;
    /* background-color: #1d92a9; */
    border-radius: 36px;
    color:black;
    font-size: 25px;
    font-family: 'Poppins';
}

.showprevhsrytblecrbn {
    position: absolute;
    top: 160px;
    left: 340px;
    width: 1853px;
    /* height: 573px; */
    height: 640px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;
    /* display: flex; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* width: 100%; */
}

/* #heading_hstiry tr td{
    padding-right: 100px;
}   */


/* Ensures spacing between all table cells */
#histry_russian_crbn {
    border-collapse: separate; /* Required for border-spacing to work */
    border-spacing: 10px; /* Adds spacing between cells in both <thead> and <tbody> */
  }
  
  /* Adds padding inside each cell for additional spacing */
  #histry_russian_crbn th,
  #histry_russian_crbn td {
    padding: 10px;
    text-align: center; /* Centers content, optional */
  } 



.showprevhsrytblecrbnv::-webkit-scrollbar {
    width: 10px; 
  }
  
  .showprevhsrytblecrbn::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
  }

.histry_bodycrbn {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    /* margin-top: -70px; */
}
.histry_russian_crbn {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.exihstrybuttoncrbn {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiry_crbn {
    font-size: 40px;
    font-family: 'Poppins';
    color: papayawhip;
}
.showprevhsrytbleportraite_crbn {
    position: absolute;
    top: 701px;
    left: 13px;
    width: 1221px;
    height: 760px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.historycards{
    position: absolute;
    top: 5px;
    left: 5px;
}
.histry_bodyportraite_crbn {
    /* width:900px; */
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_portraitecrbn {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonportraitecrbn {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstiryportraite_crbn{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}

.showprevhsrytblelndspecrbn {
    position: absolute;
    top: 335px;
    left: 340px;
    width: 1853px;
    /* height: 565px; */
    height: 630px;
    z-index: 34;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 262) 6px 3px 6px 0px inset, 
                rgba(255, 255, 255, 0.5) -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 10px;

    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.histry_bodylndspecrbn {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
}
.histry_russianlndspecrbn {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}
.exihstrybuttonlndspecrbn {
    position: absolute;
    /* top: 5px; */
    right: 14px;
    bottom: 12px;
    /* left: 5px; */
    width: 190px;
    height: 78px;
    background-color: #a1a1a1;
    border: 5px solid #988bad;
    border-radius: 26px;
    font-size: 47px;
    font-weight: bold;
    font-family: 'Poppins';
}
.heading_hstirylndspecrbn{
    font-size: 38px;
    font-family: "Poppins";
    color: papayawhip;
}
.cardsimages_crbn {
    width: 33px;
}
.antebet_carebeanlndspe {
    position: absolute;
    top: -303px;
    left: 561px;
    width: 213px;
    height: 176px;
    /* background: rgb(0 137 18); */
    /* height: 96%; */
    border-radius: 55%;
    /* pointer-events: none; */
}
.bet_carebeanlndspe {
    position: absolute;
    top: -579px;
    left: 545px;
    width: 213PX;
    /* background: rgb(0 137 18); */
    height: 176px;
    border-radius: 55%;
}
.anteglowlndspe {
    position: fixed;
    /* left: 35px; */
    right: -1543px;
    bottom: -636px;
    width: 193px;
    height: 182px;
    border-radius: 50%;
    border: 10px solid yellow;
    animation: blink normal 1s ease-in-out infinite;
    position: relative;
}
.carebean_lndspe {
    position: absolute;
    top: -258px;
    left: 600px;
    font-size: 62px;
    font-family: 'Poppins';
    color: #ffffff;
    /* z-index: 6; */
}
.Gamerulescontainerlndcrbn::-webkit-scrollbar {
    width: 22px;
    height: 3px; 
}

.Gamerulescontainerlndcrbn::-webkit-scrollbar-track {
    background: red;
    height: 10px; 
    border-radius: 5px; 
}

.Gamerulescontainerlndcrbn::-webkit-scrollbar-thumb {
    background: darkred; 
    border-radius: 10px;
    height: 3px;
}

.Gamerulescontainerlndcrbn::-webkit-scrollbar-thumb:hover {
    background: rgb(190, 165, 165); 
}

.Gamerulescontainercrbn::-webkit-scrollbar {
    width: 12px;
    height: 3px; 
}

.Gamerulescontainercrbn::-webkit-scrollbar-track {
    background: red;
    height: 10px; 
    border-radius: 5px; 
}

.Gamerulescontainercrbn::-webkit-scrollbar-thumb {
    background: darkred; 
    border-radius: 10px;
    height: 3px;
}

.Gamerulescontainercrbn::-webkit-scrollbar-thumb:hover {
    background: rgb(190, 165, 165); 
}


.carebean_betlndspe {
    position: absolute;
    top: -520px;
    left: 619px;
    font-size: 39px;
    font-family: 'Poppins';
    color: #ffffff;
}
.popupbetshighLndsp_crbn {
    position: absolute;
    top: 318px;
    right: 0;
    left: -259px;
    /* bottom: 0; */
    margin: auto;
    width: 900px;
    /* height: 400px; */
    height: 450px;
    background: hsl(0deg 0% 97% / 82%);
    z-index: 1500;
    box-shadow: 20px 20px 5px #0c0303;
    border-radius: 30px;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
}




.Gamerulescontainercrbn {
    position: absolute;
    height: 1221px;
    width: 32%;
    /* background: #ffffff; */
    color:papayawhip;
    display: flex;
    font-family: 'Poppins';
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
    animation: gradient 4s ease infinite;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}



.Gamerulescontainerportcrbn {
    position: absolute;
    height: 1362px;
    width: 59%;
   
    color:papayawhip;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 110px;
    right: 0;
    top: 210px;
    margin: auto;
    z-index: 31;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.Gamerulescontainerport ul li {
    padding: 17px;
}

.gameruleMainHeadingcrbnport {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color:papayawhip;
}
.unorderedlistitemscrbnport {
    margin-top: 125px;
    padding: 0px 0px 0px 30px;
}
.Gamerulescontainerlndcrbn {
    position: absolute;
    height: 1221px;
    width: 32%;
   
    color:papayawhip;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 20px;
    z-index: 13;
    overflow: auto;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 31;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.gameruleMainHeadingcrbnlnd {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color:papayawhip;
}
.unorderedlistitemscrbnlnd {
    margin-top: 217px;
    padding: 0px 0px 0px 30px;
}
.cardDeckLandscapecrbn {
    position: absolute;
    right: 651px;
    top: 123px;
}
.lndspinfocrbn {
    position: absolute;
    /* top: 890px; */
    top: 420px;
    font-size: 35px;
    font-family: "Poppins";
    /* z-index: 5; */
    z-index: 0;
    color: #ffffff;
    width: 23%;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-weight: bold; */
    padding: 1%;
    border: 4px solid #00ffadb0;
    border-radius: 10%;
    left: 15%;
    /* right: 0; */
    /* margin: auto; */
}
.popup_foldLndspcrbn {
    position: absolute;
    top: 583px;
    bottom: 0;
    margin: auto;
    left: -183px;
    right: 0;
    width: 879px;
    height: 285px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 22;
    
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
   
}
.exit_popupcrbn{
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 100;
}

  
.infotext_windowscrbn {
    position: relative;
    top: 880px;
    right: 1px;
    width: 441px;
    height: 266px;
    border: 5px solid slategrey;
    /* border-image: linear-gradient(to right, #a29585, #976565, #296790, #403838); */
    border-image-slice: 1;
    border-radius: 16px;
}

.windowsinfocrbn {
    position: absolute;
    /* top: 932px; */
    font-size: 27px;
    font-family: 'Poppins';
    z-index: 5;
    color: #ffffff;
    /* background: red; */
    width: 419px;
    height: 254px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    left: 0px;
    right: 0;
    margin: auto;
    padding: auto;
}
/* 
#undobtn button:active{
    scale: 0.9;
    
  } */

  .cardDeckwindowscrbn {
    position: absolute;
    top: 144px;
    right: 15%;
    /* left: 5px; */
}
.unorderedlistitemscrbn {
    margin-top: 171px;
    padding: 0px 0px 0px 46px;
}
.gameruleMainHeadingcrbn {
    position: absolute;
    top: -4px;
    font-size: 53px;
    color:papayawhip;

}


.LimitsPopupcrbn {
    position: absolute;
    top: 400px;
    left: 0px;
    right: 0;
    z-index: 34;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins';
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_landspecrbn {
    position: absolute;
    /* top: 149px; */
    top: 400px;
    left: 0px;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    background: repeating-linear-gradient(45deg, orange, transparent 14px);
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.LimitsPopup_portraitecrbn {
    position: absolute;
    top: 850px;
    left: 0;
    right: 0;
    margin: auto;
    width: 750px;
    height: 300px;
    /* background: repeating-linear-gradient(45deg, orange, transparent 14px); */
    /* background: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 38px;
    font-family: "poppins";
    color: #ffffff;
    background: linear-gradient(#040610, #131D50, #0E1E33);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.exit_popupcrbn{
    position: absolute;
    right: 2%;
    top: 2%;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 100;
}
.cardDeckportraitecrbn {
    position: absolute;
    right: 253px;
    top: 215px;
}
.shoWPAYOUTTABLE {
    position: absolute;
    top: 99px;
    right: 149px;
    width: 669px;
    height: 740px;
    z-index: 34;
    background: linear-gradient(#d4d0b0, rgb(4, 3, 1), #000000);
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, 
                #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 45px;
    white-space: nowrap;

    transform-origin: top; 
    animation: payouts 1s ease-in-out forwards;
}

@keyframes payouts {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}

.payout_crbn {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.payoutheading {
    font-size: 40px;
    font-family: 'Poppins';
    color: black;
    
}

.payout_body {
    width: 100%;
    /* text-align: center; */
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    left: 68px;
    /* margin-top: -70px; */
}
.playerwinamount{
    font-size: 40px;
    font-family: 'Poppins';
    color: papayawhip;

}
.shoWPAYOUTTABLEprtrt {
    position: absolute;
    /* top: 99px; */
    bottom: -1742px;
    right: 531px;
    width: 669px;
    height: 740px;
    z-index: 34;
    background: linear-gradient(#d4d0b0, rgb(4, 3, 1), #000000);
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, 
                #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 45px;
    white-space: nowrap;
    transform-origin: top;
    animation: payouts 1s ease-in-out forwards;
}

@keyframes payouts {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}

.payout_crbnprtrt {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.payoutheadingprtrt {
    font-size: 40px;
    font-family: 'Poppins';
    color: black;
    
}

.payout_bodyprtrt {
    width: 100%;
    /* text-align: center; */
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    left: 68px;
    /* margin-top: -70px; */
}
.shoWPAYOUTTABLElndspe {
    position: absolute;
    top: 245px;
    /* right: 149px; */
    width: 669px;
    height: 740px;
    z-index: 34;
    background: linear-gradient(#d4d0b0, rgb(4, 3, 1), #000000);
    box-shadow: rgb(208, 208, 208) 6px 3px 6px 0px inset, 
                #34a5ff -3px -3px 193px -28px inset;
    padding: 20px;
    border-radius: 45px;
    white-space: nowrap;
    transform-origin: top;
    animation: payouts 1s ease-in-out forwards;
}

@keyframes payouts {
    0% {
        transform: scaleY(0); 
        opacity: 0;
    }
    50%{
        transform: scaleX(0); 
        opacity: 0.5;

    }
    100% {
        transform: scaleY(1); 
        opacity: 1;
    }
}

.payout_crbnLndspe {
    width: 100%;
    height: auto;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    color: black;
    margin: 10px 0;
}

.payoutheadinglndspe {
    font-size: 40px;
    font-family: 'Poppins';
    color: black;
    
}

.payout_bodylndspe {
    width: 100%;
    /* text-align: center; */
    font-size: 34px;
    color: white;
    font-family: 'Poppins', sans-serif;
    position: relative;
    top: 50px;
    left: 68px;
    /* margin-top: -70px; */
}